import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useMemo } from "react";
import fr from "apexcharts/dist/locales/fr.json";

import Icon from "components/Icon/Icon";
import Table from "common/components/Table/Table";

export default function SiteStatistics({
  data,
  isDetail,
}: {
  data: any;
  isDetail: boolean;
}) {
  const { t } = useTranslation();

  const siteDetailStats = useMemo(
    () => [
      {
        name: t("stats.nbThreads"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalThreads ? data.totalThreads : 0,
      },
      {
        name: t("stats.nbPosts", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalPosts ? data.totalPosts : 0,
      },
      {
        name: t("stats.nbSubscribers"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalFollowers ? data.totalFollowers : 0,
      },
    ],
    [data, t]
  );

  const siteThreadsByType = {
    datasets:
      data && data.threadsPerType && data.threadsPerType.length > 0
        ? data?.threadsPerType?.map(
            (thread: { type: string; data: any[] }) => ({
              name: thread.type,
              data: thread?.data?.map((d) => ({
                x: d.date,
                y: d.value,
              })),
              borderWidth: 1,
            })
          )
        : [],
  };

  const postsDataset = {
    labels: data.postsPerSite?.map(
      (post: { date: string; value: number }) => post.date
    ),
    datasets: [
      {
        name: t("stats.newPostsByDate"),
        data:
          data.postsPerSite?.map(
            (post: { date: string; value: number }) => post.value
          ) ?? [],
      },
    ],
  };

  const sortAndIntercalateEntities = (entities: any[]) => {
    if (!entities?.length) return [];

    // Ajouter un préfixe aux noms des sites avec un parent
    const entitiesWithPrefix = entities.map((entity) => {
      const hasParent = entity?.site?.parent?.id != null;
      return {
        ...entity,
        site: {
          ...entity.site,
          name: hasParent ? `- ${entity.site.name}` : entity.site.name, // Préfixer le nom si parent
        },
      };
    });

    // Trier les entités par le champ "name" dans l'objet site
    entitiesWithPrefix.sort((a: any, b: any) =>
      a?.site?.name?.localeCompare(b?.site?.name)
    );

    // Création de la structure de regroupement par parent (via l'objet site)
    const groupedEntities = entitiesWithPrefix.reduce((acc, entity) => {
      const parentId = entity?.site?.parent?.id || null; // Parent null pour les racines
      if (!acc[parentId]) {
        acc[parentId] = [];
      }
      acc[parentId].push(entity);
      return acc;
    }, {});

    // Fonction récursive pour organiser et intercaler les entités
    const intercalateEntities = (parentId: any): any[] => {
      const result: any[] = [];
      const grouped = groupedEntities[parentId] || [];
      // eslint-disable-next-line no-restricted-syntax
      for (const entity of grouped) {
        result.push(entity);
        // Appel récursif pour les enfants du site actuel
        result.push(...intercalateEntities(entity?.site?.id));
      }
      return result;
    };

    // Démarrage de la fonction récursive avec parentId null (racine)
    return intercalateEntities(null);
  };

  const multipleSitesData = useMemo(() => {
    if (data.sitesData) {
      return sortAndIntercalateEntities(data.sitesData ?? []);
    }
    return [];
  }, [data]);

  return (
    <div className="h-5/6">
      {isDetail && (
        <dl className="mt-3 grid grid-cols-1 xl:grid-cols-3 gap-3 mb-3">
          {siteDetailStats.map((item) => (
            <div
              key={item?.name}
              className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-indigo-500 rounded-md p-3">
                  {item?.icon}
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                  {item?.name}
                </p>
              </dt>
              <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                <p className="text-2xl font-semibold text-gray-900">
                  {item?.stat}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      )}

      {isDetail ? (
        <div className="w-full h-5/6 grid grid-cols-1 xl:grid-cols-2 gap-3">
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "threadsByType",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                title: {
                  text: t("stats.threadsByType"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                stroke: {
                  curve: "smooth",
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={siteThreadsByType.datasets}
              type="line"
              height="100%"
              width="100%"
            />
          </div>
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "sitePosts",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                title: {
                  text: t("stats.newPostsByDate"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                stroke: {
                  curve: "straight",
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                  categories: postsDataset.labels,
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
                // labels: posts.labels,
              }}
              series={postsDataset.datasets}
              type="bar"
              height="100%"
            />
          </div>
        </div>
      ) : (
        <div className="w-full gap-3">
          <div className=" justify-center white-box">
            <Table
              data={multipleSitesData ?? []}
              columns={[
                {
                  Header: t("titlePages.sites", {
                    wording: getWording(false, true),
                  }),
                  accessor: "site.name",
                  disableSortBy: true,
                },
                { Header: t("stats.nbThreads"), accessor: "nbThreads" },
                { Header: t("stats.nbPosts"), accessor: "nbPosts" },
                { Header: t("stats.nbSubscribers"), accessor: "nbFollowers" },
              ]}
              isFetching={false}
              pagination={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
