import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useMemo } from "react";
import Icon from "components/Icon/Icon";
import fr from "apexcharts/dist/locales/fr.json";
import { Constants } from "constants/Constants";
import useAuth from "common/hooks/useAuth";

export default function OrganizationStatistics({ data }: { data: any }) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const userStats = useMemo(
    () => [
      {
        name: t("stats.nbUsers", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.userStats && data.userStats.nbTotalUsers
            ? data.userStats.nbTotalUsers
            : 0,
      },
      {
        name: t("stats.nbLoggedUsers"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.userStats && data.userStats.nbLoggedUsers
            ? data.userStats.nbLoggedUsers
            : 0,
      },
      // {
      //   name: t("stats.nbDownloads"),
      //   icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
      //   stat:
      //     data && data.userStats && data.userStats.nbDownloads
      //       ? data.userStats.nbDownloads
      //       : 0,
      // },
    ],
    [data, t]
  );

  const organizationStats = useMemo(
    () => [
      {
        name: t("stats.nbSite", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbSites ? data.nbSites : 0,
      },
      {
        name: t("stats.nbPosts"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbPosts ? data.nbPosts : 0,
      },
      {
        name: t("stats.nbThreads"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbThreads ? data.nbThreads : 0,
      },
    ],
    [data, t]
  );

  const usersByDate = {
    datasets: [
      {
        name: t("stats.loggedUsers"),
        data: data.userStats?.loggedUsersByDate?.map(
          (lu: { date: string; value: string }) => ({
            x: lu.date,
            y: lu.value,
          })
        ),
      },
      {
        name: t("stats.anonymousUsers"),
        data: data.userStats?.anonymousUsersByDate?.map(
          (au: { date: string; value: string }) => ({
            x: au.date,
            y: au.value,
          })
        ),
      },
    ],
  };

  const usersAndroidByDate = {
    datasets: [
      {
        name: t("stats.downloads"),
        type: "column",
        data: data.userStats?.androidStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.downloads,
          })
        ),
      },
      {
        name: t("stats.deletions"),
        type: "column",
        data: data.userStats?.androidStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.deletions,
          })
        ),
      },
      {
        name: t("stats.totalDownloads"),
        type: "line",
        data: data.userStats?.androidStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.totalUsers,
          })
        ),
      },
    ],
  };

  const usersAppledByDate = {
    datasets: [
      {
        name: t("stats.downloads"),
        type: "column",
        data: data.userStats?.appleStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.downloads,
          })
        ),
      },
      {
        name: t("stats.redownload"),
        type: "column",
        data: data.userStats?.appleStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.redownload,
          })
        ),
      },
      {
        name: t("stats.updates"),
        type: "line",
        data: data.userStats?.appleStats?.map(
          (au: { date: string; value: any }) => ({
            x: au.date,
            y: au.value?.updates,
          })
        ),
      },
    ],
  };

  return (
    <>
      {data?.userStats && (
        <>
          <dl className="mt-3 grid grid-cols-1 lg:grid-cols-3 gap-3 mb-3">
            {userStats.map((item) => (
              <div
                key={item?.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item?.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item?.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item?.stat}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-3 mb-3">
            <div className="h-80 justify-center white-box">
              <Chart
                options={{
                  chart: {
                    id: "nbUsersByDate",
                    locales: [fr],
                    defaultLocale: "fr",
                  },
                  stroke: {
                    curve: "straight",
                  },
                  title: {
                    text: t("stats.nbUsersByDate"),
                    align: "center",
                    margin: 18,
                    style: {
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#263238",
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                  xaxis: {
                    type: "datetime",
                    labels: {
                      datetimeFormatter: {
                        year: "yyyy",
                        month: "MMM 'yy",
                        day: "dd MMM",
                        hour: "HH:mm",
                      },
                    },
                  },
                  yaxis: {
                    tickAmount: 5,
                    labels: {
                      formatter: (value) => Math.round(value).toString(),
                    },
                  },
                  noData: {
                    text: "Aucune données à afficher",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "18px",
                      color: "#263238",
                    },
                  },
                }}
                series={usersByDate.datasets}
                height="100%"
                type="line"
              />
            </div>
            {((Constants.DEDICATED_APP && currentUser?.isAdmin) ||
              currentUser?.isSuperadmin) && (
              <div className="h-80 justify-center white-box">
                <Chart
                  options={{
                    chart: {
                      id: "androidStatistics",
                      locales: [fr],
                      defaultLocale: "fr",
                    },
                    stroke: {
                      curve: "straight",
                    },
                    title: {
                      text: t("stats.androidStatistics"),
                      align: "center",
                      margin: 18,
                      style: {
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#263238",
                      },
                    },
                    legend: {
                      position: "bottom",
                    },
                    xaxis: {
                      type: "datetime",
                    },
                    yaxis: [
                      {
                        tickAmount: 5,
                        labels: {
                          formatter: (value) => Math.round(value).toString(),
                        },
                        seriesName: [
                          t("stats.downloads"),
                          t("stats.deletions"),
                        ],
                      },
                      {
                        opposite: true,
                        title: { text: "Total utilisateurs" },
                        tickAmount: 5,
                        labels: {
                          formatter: (value) => Math.round(value).toString(),
                        },
                      },
                    ],

                    noData: {
                      text: "Aucune données à afficher",
                      align: "center",
                      verticalAlign: "middle",
                      offsetX: 0,
                      offsetY: 0,
                      style: {
                        fontSize: "18px",
                        color: "#263238",
                      },
                    },
                  }}
                  series={usersAndroidByDate.datasets}
                  height="100%"
                  type="line"
                />
              </div>
            )}
          </div>
          {((Constants.DEDICATED_APP && currentUser?.isAdmin) ||
            currentUser?.isSuperadmin) && (
            <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-3 mb-3">
              <div className="h-80 justify-center white-box">
                <Chart
                  options={{
                    chart: {
                      id: "appleStatistics",
                      locales: [fr],
                      defaultLocale: "fr",
                    },
                    stroke: {
                      curve: "straight",
                    },
                    title: {
                      text: t("stats.appleStatistics"),
                      align: "center",
                      margin: 18,
                      style: {
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#263238",
                      },
                    },
                    legend: {
                      position: "bottom",
                    },
                    xaxis: {
                      type: "datetime",
                    },
                    yaxis: [
                      {
                        tickAmount: 5,
                        labels: {
                          formatter: (value) => Math.round(value).toString(),
                        },
                        seriesName: [
                          t("stats.downloads"),
                          t("stats.redownload"),
                        ],
                      },
                      {
                        opposite: true,
                        title: {
                          text: "Mises à jour",
                          style: {
                            color: "#FEB019",
                          },
                        },
                        tickAmount: 5,
                        axisBorder: {
                          show: true,
                          color: "#FEB019",
                        },
                        labels: {
                          formatter: (value) => Math.round(value).toString(),
                          style: {
                            colors: "#FEB019",
                          },
                        },
                      },
                    ],

                    noData: {
                      text: "Aucune données à afficher",
                      align: "center",
                      verticalAlign: "middle",
                      offsetX: 0,
                      offsetY: 0,
                      style: {
                        fontSize: "18px",
                        color: "#263238",
                      },
                    },
                  }}
                  series={usersAppledByDate.datasets}
                  height="100%"
                  type="line"
                />
              </div>
            </div>
          )}
        </>
      )}

      <dl className="mt-3 grid grid-cols-1 lg:grid-cols-3 gap-3 mb-3">
        {organizationStats.map((item) => (
          <div
            key={item?.name}
            className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                {item?.icon}
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                {item?.name}
              </p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">
                {item?.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
}
