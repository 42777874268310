/* eslint-disable react/no-array-index-key */
import DebounceInput from "common/components/DebounceInput/DebounceInput";
import {
  HTMLAttributes,
  ClassAttributes,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  TdHTMLAttributes,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useSortBy, useTable } from "react-table";
import "react-activity/dist/library.css";
import Pagination from "./Pagination";
import PageLoading from "../PageLoading/PageLoading";

type Props = {
  data: any[];
  columns: any[];
  isFetching: boolean;
  pageCount?: number;
  itemsPerPage?: number;
  itemsCount?: number;
  filters?: { [key: string]: any };
  setFilter?: (filter: any, value: any) => void;
  setPageIndex?: (pageIndex: number) => void;
  pageIndex?: number;
  pagination?: boolean;
};

function Table({
  data,
  columns,
  isFetching,
  pageCount: controlledPageCount,
  itemsCount = 0,
  itemsPerPage = 0,
  filters,
  setFilter,
  pageIndex = 0,
  setPageIndex,
  pagination,
}: Props) {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex, pageSize: 10 },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pageIndex !== currentPageIndex && setPageIndex) {
      setPageIndex(currentPageIndex);
    }
  }, [currentPageIndex, pageIndex, setPageIndex]);

  const handleChangeFilter = useCallback(
    (name: any, value: any) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      if (setFilter) setFilter(name, value);
    },
    [gotoPage, setFilter]
  );

  return (
    <div>
      <div className="mt-8 flex flex-col relative min-h-[300px]">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="relative inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-auto max-h-[calc(100vh-8rem)] w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                className="relative w-full divide-y divide-gray-300 table-fixed"
                {...getTableProps()}
              >
                <thead className="bg-gray-50 sticky top-0">
                  {headerGroups.map(
                    (
                      headerGroup: {
                        getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                          ClassAttributes<HTMLTableRowElement> &
                          HTMLAttributes<HTMLTableRowElement>;
                        headers: any[];
                      },
                      index: any
                    ) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={`header-${index}`}
                      >
                        {headerGroup.headers.map((column: any, key: any) =>
                          column.hidden ? null : (
                            <th
                              key={`column-${key}`}
                              className={`px-3 py-3.5 text-sm font-semibold text-gray-900
                  ${
                    key !== headerGroup.headers.length - 1
                      ? "text-left"
                      : "text-right"
                  }`}
                            >
                              <span
                                className="th-label"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {!column.filterName && column.render("Header")}
                                {column.isSorted &&
                                  (column.isSortedDesc ? (
                                    <i className="ml fas fa-sort-down" />
                                  ) : (
                                    <i className="ml fas fa-sort-up" />
                                  ))}
                              </span>
                              {column.filterName &&
                                (column.filterComponent ? (
                                  <column.filterComponent
                                    value={filters?.[column.filterName]}
                                    data-cy={column.filterName}
                                    onChange={(value: any) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      column.customFormat
                                        ? handleChangeFilter(
                                            column.filterName,
                                            column.customFormat(value)
                                          )
                                        : handleChangeFilter(
                                            column.filterName,
                                            value
                                          );
                                    }}
                                  />
                                ) : (
                                  <DebounceInput
                                    className="input is-filter w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                    placeholder={column.render("Header")}
                                    value={filters?.[column.filterName]}
                                    onChange={(value) =>
                                      handleChangeFilter(
                                        column.filterName,
                                        value
                                      )
                                    }
                                  />
                                ))}
                            </th>
                          )
                        )}
                      </tr>
                    )
                  )}
                </thead>
                <tbody className="bg-white" {...getTableBodyProps()}>
                  {!isFetching && !page.length && (
                    <tr>
                      <td
                        colSpan={headerGroups[0].headers.length}
                        className="whitespace-normal px-3 py-2 text-sm text-gray-500"
                      >
                        <span className="table-no-result">
                          {t("common.noResult")}
                        </span>
                      </td>
                    </tr>
                  )}
                  {page.map((row: any, index: any) => {
                    prepareRow(row);
                    return (
                      <tr
                        key={`row-${index}`}
                        className={index % 2 === 0 ? undefined : "bg-gray-50"}
                        data-tr={row.name}
                        {...row.getRowProps()}
                      >
                        {row.cells.map(
                          (cell: {
                            column: {
                              hidden: any;
                              name: any;
                              className: any;
                            };
                            getCellProps: (arg0: {
                              className: any;
                            }) => JSX.IntrinsicAttributes &
                              ClassAttributes<HTMLTableDataCellElement> &
                              TdHTMLAttributes<HTMLTableDataCellElement>;
                            render: (
                              arg0: string
                            ) =>
                              | string
                              | number
                              | boolean
                              | ReactElement<
                                  any,
                                  string | JSXElementConstructor<any>
                                >
                              | ReactFragment
                              | ReactPortal
                              | null
                              | undefined;
                          }) =>
                            cell.column.hidden ? null : (
                              <td
                                key={`cell-${row.name}`}
                                data-cy={row.name}
                                data-row={cell.column.name}
                                {...cell.getCellProps({
                                  className: cell.column.className,
                                })}
                                className="whitespace-normal px-3 py-2 text-sm text-gray-500"
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                        )}
                      </tr>
                    );
                  })}

                  {pagination &&
                    page.length > 0 &&
                    controlledPageCount !== 1 && (
                      <tr className="paginator-tr sticky bottom-0">
                        <td
                          colSpan={headerGroups[0].headers.length}
                          className="p-0"
                        >
                          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                            <div className="flex-1 flex justify-between sm:hidden">
                              <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                {t("common.preview")}
                              </a>
                              <a
                                href="#"
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                {t("common.next")}
                              </a>
                            </div>
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                              <div>
                                <p className="text-sm text-gray-700">
                                  <span className="font-medium ml-1">
                                    {1 + pageIndex * itemsPerPage}
                                  </span>
                                  /
                                  <span className="font-medium">
                                    {itemsCount <
                                    pageIndex * itemsPerPage + itemsPerPage
                                      ? itemsCount
                                      : pageIndex * itemsPerPage + itemsPerPage}
                                  </span>
                                  {t("common.of")}{" "}
                                  <span className="font-medium">
                                    {itemsCount}
                                  </span>{" "}
                                  {t("common.results")}
                                </p>
                              </div>
                              <div>
                                <Pagination
                                  goToPage={gotoPage}
                                  nextPage={nextPage}
                                  previousPage={previousPage}
                                  // currentPage={pageIndex}
                                  totalPage={controlledPageCount ?? 1}
                                  disabled={isFetching}
                                  // new
                                  pageIndex={pageIndex}
                                  itemsPerPage={itemsPerPage}
                                  itemsCount={itemsCount}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isFetching === true && <PageLoading />}
      </div>
    </div>
  );
}

export default Table;
